/**
 * @author gudiskis PrivaSphere confidential, (c) 2023 - 2024 all rights reserved
 */
import constVarLan from "../../../angular/src/assets/i18n/fr.json";
export const frTranslation = {
    ts_Delete: "Supprimer",
    ts_CheckAttaBeforeDispatch: "Vérifie la pièce jointe avant l&#39;envoi",
    ts_TooBig: "Trop grand",
    ts_attachment: "pièce jointe",
    ts_available: "disponible",
    ts_cumulated: "cumulated",
    ts_fileNameExtension: "filename-extension",
    ts_instead: "au lieu de",
    ts_moreInfo: "Plus d'infos",
    ts_in_aNewWindow: "dans une nouvelle fenêtre de navigateur",
    ts_MoreThanOneNumberGroupPresent: "Plus d'un groupe de nombres est présent.",
    ts_NoNumberPresent: "Aucun nombre n'est présent.",
    ts_TooManyDecimalDelimiters: "Trop de délimiteurs décimaux.",
    ts_TooManyMinusSigns: "Trop de signes négatifs.",
    ts_LeadingZerosRemoved: "Zéros initiaux supprimés.",
    ts_WhitespaceRemoved: "Espaces supprimés.",
    ts_CommasReplacedWithDots: "Virgules remplacées par des points.",
    ts_MultipleMinusSignsReducedToOne: "Plusieurs signes moins réduits à un.",
    ts_MultipleDecimalDelimitersReducedToOne: "Plusieurs délimiteurs décimaux réduits à seulement",
    ts_MultipleCharsReducedToOne: "Plusieurs lettres consecutives reduit à:",
    ts_NonNumberRemoved: "Caractères non numériques supprimés (sauf - et . ).",
    ts_NumberRounded: "Le nombre a été arrondi.",
    ts_FailedRoundingTheNumber: "Échec de l'arrondi du nombre.",
    ts_MinusSignNotAllowed: "Le signe moins n'est pas autorisé et a été supprimé.",
    ts_QuoteRemoved: "Guillemets ('\") supprimés.",
    ts_Input: "Entrée",
    ts_IsInvalid: "est invalide",
    ts_showFiles: "Afficher les fichiers",
    ts_hideFiles: "Masquer les fichiers",
    ts_deleteAllFiles: "Supprimer tous les fichiers",
    sd_noFilesSelected: constVarLan["sd_noFilesSelected"],
    ts_filesSelected: "fichiers à télécharger",
    ts_onlyOneFileAllowed: "Seul un fichier est autorisé pour cette entrée",
    sd_From: constVarLan["sd_From"],
    sd_to: constVarLan["sd_to"],
    ts_correctGroups: "Entrées de groupe correctes multiples détectées, choisissez-en une : ",
    ts_incorrectCharacter: "Caractère incorrect détecté : ",
    ts_inputTooLong: "L'entrée contient trop de caractères. Maximum autorisé :",
    ts_currently: "Actuellement : ",
    ts_inputTooShort: "L'entrée contient trop peu de caractères. Minimum requis :",
    ts_outOfRangeDate: "La date entrée est hors de la plage.",
    ts_outOfRangeTooEarly: "est trop tard lorsque le maximum autorisé est",
    ts_outOfRangeTooLate: "est trop tôt lorsque le minimum autorisé est",
    ts_noValidDateFound: "La date entrée n'est pas valide.",
    ts_dateChangedTo: "n'est pas une date valide et a été changée en",
    ts_dateChangedFrom: "La date entrée a été changée de",
    ts_unsupportedDateFormat: "Format de date non pris en charge",
    ts_parameter: "Paramètre",
    sd_Month: constVarLan["sd_Month"],
    sd_Year: constVarLan["sd_Year"],
    sd_day: constVarLan["sd_day"],
    ts_pleaseChange: "Veuillez changer",
    ts_toMatchTheFormat: "pour correspondre au format correct",
    ts_whitespacesRemoved: "espaces blancs supprimés",
    ts_whitespaceRemoved: "espace blanc supprimé",
    ts_wrongSeparators: "sont des séparateurs incorrects, le format correct est",
    ts_dateFormatChangedTo: "Format de date changé en",
    sd_of: constVarLan["sd_of"],
    sd_moreInfo: `${constVarLan["sd_moreIn"]} (${constVarLan["sd_in_aNewWindow"]})`,
    sd_orLess: constVarLan["sd_orLess"],
    sd_interoperable: constVarLan["sd_interoperable"],
    sd_registeredTitlePrefix: constVarLan["sd_registeredTitlePrefix"],
    sd_PermittedExtensions: constVarLan["sd_PermittedExtensions"],
    ts_leadtrailRemoved: "supprimer les caractères non-ascii et spéciaux de début et de fin",
    ts_emailmusthaveat: "An " + constVarLan["sd_Email_ad"] + " doit avoir un \"\@\" !",
    ts_emailmusthavedomain: "An " + constVarLan["sd_Email_ad"] + " doit se terminer par \"domain.topLevelDomain\" par exemple \"ti.ch\" !",
    ts_wrongEmailSeparators: constVarLan["sd_emailEntryTooltip"] + " - " + constVarLan["sd_not"] + " \";\" (" + constVarLan["sd_semicolon"] + ") !",
    ts_emailmustonlyone: "Un seul " + constVarLan["sd_Email_ad"] + " est autorisé !",
    ts_extraDotRemovedfromAdjacenttoAt: "le point supplémentaire est supprimé pour celui adjacent à \"\@\" !",
    ts_invalidAHVNummerRemoved: "Le Numéro AVS non valide a été supprimé !",
    ts_nonMatchingCharsRemoved: "lettres non valides retirées!",
    sd_Country: constVarLan["sd_Country"],
    sd_mobileNrForQesAuthoriz: constVarLan["sd_mobileNrForQesAuthoriz"],
    sd_GivenName: constVarLan["sd_GivenName"],
    sd_Lastname: constVarLan["sd_Lastname"],
    sd_SubmitTxt: constVarLan["sd_SubmitTxt"],
    ts_phoneNumber: "Numéro de téléphone",
    ts_enterQESInfo: "Entrez les informations QES",
    ts_defaultError: "Une erreur s'est produite, veuillez réessayer",
    ts_fillInAllFields: "Veuillez remplir tous les champs",
    ts_phoneNumberError: "Assurez-vous que le numéro de téléphone est correct",
    ts_emailError: "Assurez-vous que l'email est correcte",
    ts_qesSubmittSuccess: "Vos informations QES ont été soumises avec succès",
    ts_qesEmailNotFound: "Votre email n'a pas été trouvée dans le système, veuillez remplir votre prénom et nom",
    ts_qesError: "Échec de la mise à jour de vos informations QES",
    ts_because: "parce que",
    ts_qesErrorSession: "Échec de la vérification de la session QES",
    ts_success: "Succès",
    ts_countryCodeError: "Le code pays reçu est incorrect",
    ts_qesNameNotFound: "Le nom ou le prénom ne peut pas être vide",
    ts_longWaitTimeTitle: "Temps d'attente plus long que d'habitude",
    ts_longWaitTimeText1: "Désolé pour les longs délais d'attente ! Malheureusement, le traitement de votre demande prend plus de temps que prévu. Il se peut que votre demande ait déjà été traitée et envoyée au bon destinataire. Dans ce cas, veuillez vérifier si vous avez reçu un accusé de réception de PrivaSphere. Ce reçu de soumission est une confirmation légale que votre formulaire a été soumis.",
    ts_longWaitTimeText2: "Si vous avez reçu un reçu de dépôt mais que la page du formulaire indique qu'il est toujours en cours de soumission, veuillez nous signaler ce bogue via: ",
    ts_includeLogs: "Veuillez télécharger les journaux et les inclure dans l'email en tant que pièce jointe.",
    ts_downloadLogs: "Télécharger les journaux",
    ts_inputNumber: "Numéro d'entrée",
    ts_inputAmendedWithProperDots: "L'entrée a été modifiée avec les points appropriés",
    ts_RemovedNonLetters: "Les caractères non alphabétiques ont été supprimés.",
    ts_error: "Erreur",
    ts_whiteSpacesAtStartEndRemoved: "Les espaces au début et à la fin ont été supprimés.",
    ts_InvalidPhoneNumber: "Le numéro de téléphone ne suit pas le format correct, veuillez le corriger.",
    ts_secondsElapsedWaitPanel: "Jusqu'à présent: {0}",
    ts_numberIsTooLow: "L'entrée est inférieure au minimum défini '{0}'.",
    ts_numberIsTooHigh: "L'entrée est supérieure au maximum défini '{0}'.",
    ts_showRegexForUser: "L'expression régulière est '{0}'. Si vous rencontrez des problèmes, veuillez contacter {1} en joignant une capture d'écran de cette erreur, y compris le nom du formulaire et les journaux du navigateur dans l'e-mail.",
    ts_formSupportDialogTitle: "Demander de l'assistance",
    ts_formSupportDialogBody: "Si vous rencontrez des difficultés avec le formulaire ou si vous pensez qu'il y a une erreur, veuillez envoyer un e-mail au support et expliquer votre problème.",
    sd_supportButtonInForm: constVarLan["sd_supportButtonInForm"],
    ts_numberOutOfRange: "L'entrée {0} est hors de la plage autorisée. ",
    ts_minAllowed: "Le minimum autorisé est {0}",
    ts_maxAllowed: "Le maximum autorisé est {0}",
    ts_fileAlreadyExists: "Cette entrée ne peut contenir qu'un seul fichier à la fois et elle en possède déjà un, veuillez supprimer l'ancien fichier si vous souhaitez télécharger un nouveau fichier.",
};
