/**
 * @author gudiskis, ancona, hauser PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 *
 */
import { Logger, LoggerFactory } from "myd-commons";
import { PageData } from "./PageData";
import { getTypo3LanguageNumber } from "./languages/LanguageFormat";
import constVar from "../../angular/src/assets/const.json";
export const BYTES_IN_ONE_MB = 1024 * 1024;
export const BYTES_IN_ONE_GB = 1024 * 1024 * 1024;
export class Utils {
    static getCurrentTimeString() {
        const currentDate = new Date();
        const year = currentDate.getFullYear().toString().substr(2);
        const month = this.padZero(currentDate.getMonth() + 1);
        const day = this.padZero(currentDate.getDate());
        const hours = this.padZero(currentDate.getHours());
        const minutes = this.padZero(currentDate.getMinutes());
        const seconds = this.padZero(currentDate.getSeconds());
        return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    }
    static padZero(num) {
        return num < 10 ? `0${num}` : num.toString();
    }
    static getElementInput(id) {
        return document.getElementById(id);
    }
    static completeRequestURL() {
        return this.completeReqURL('?');
    }
    static completeReqURL(delim) {
        // TODO: replace param names with GUIConst constants
        let dotD = '.;';
        let jsessionIdString = this.getParam('jsessionid')
            ? 'jsessionid=' + this.getParam('jsessionid')
            : '';
        let loginString = this.getParam('login')
            ? delim + 'login=' + this.getParam('login')
            : '';
        let n0wString = this.getParam('n0w') ? '&n0w=' + this.getParam('n0w') : '';
        let res = dotD + jsessionIdString + loginString + n0wString;
        return res;
    }
    static getParam(name) {
        var rx = new RegExp('[;?&]' + name + '=([^&?/]+).*$');
        var returnVal = window.location.href.match(rx);
        return returnVal === null ? '' : returnVal[1];
    }
    static createHelpLink(pageDataID) {
        var _a, _b, _c, _d;
        let pageData = new PageData();
        return `/h/?id=${(_b = (_a = pageData.findPageByC(pageDataID)) === null || _a === void 0 ? void 0 : _a["n"]) !== null && _b !== void 0 ? _b : ""}&amp;L=${getTypo3LanguageNumber(currentLanguageTS)}#${(_d = (_c = pageData.findPageByC(pageDataID)) === null || _c === void 0 ? void 0 : _c["t"]) !== null && _d !== void 0 ? _d : ""}`;
    }
    static getContaFrmSnd() {
        let contaFrmSnd = document.getElementById(constVar["sd_contaFrmSndCustom"]);
        if (!contaFrmSnd) {
            contaFrmSnd = document.getElementById(constVar["sd_contaFrmSnd"]);
        }
        if (!contaFrmSnd) {
            contaFrmSnd = document.getElementById(constVar["sd_navigatPrio"]);
        }
        return contaFrmSnd;
    }
    static getCreateSnd() {
        let createFrmSnd = document.getElementById(constVar["sd_navigatPrio"]);
        return createFrmSnd;
    }
    static replaceAndCount(str, charToReplace, replacementChar) {
        const regex = new RegExp(charToReplace, 'g'); // 'g' flag for global replacement
        const count = (str.match(regex) || []).length; // Count occurrences
        const modifiedString = str.replace(regex, replacementChar); // Replace all occurrences
        return { modifiedString, count };
    }
    static setStringToValueWhiteSpace(str, inputDomElt) {
        if (null == inputDomElt) {
            Utils.logger.warn("null == inputDomElt");
            return;
        }
        const typ = inputDomElt.type;
        typ.toLowerCase();
        let isEmail = null != typ;
        if (isEmail) {
            isEmail = typ.toLowerCase() === "email";
        }
        if (isEmail) {
            // when type is email, typescript automatically trims, FireFox (at least older versions doesn't)
            inputDomElt.type = 'text';
        }
        inputDomElt.value = str;
        if (isEmail) {
            //inputDomElt.type = 'email';
            Utils.logger.info("not setting back to 'text' since otherwise immediately trimmed");
        }
        return;
    }
    static formatTestError(i, expctd, test, actual, dbg = "") {
        if (dbg && 0 < dbg.length) {
            return `i: '${i}', FAIL Test: '${dbg}', Input: '${test}', Expected: '${expctd}', Actual: '${actual}'`;
        }
        return `i: '${i}', Input: '${test}', Expected: '${expctd}', Actual: '${actual}'`;
    }
    static isNumeric(value) {
        return /^-?\d+$/.test(value);
    }
    static checkIfLogExists(logStatement) {
        let arrayOfLogs = Logger.getLogs();
        let logExists = false;
        for (let log of arrayOfLogs) {
            if (log.includes(logStatement)) {
                logExists = true;
            }
        }
        return logExists;
    }
    /*
    * https://git.privasphere.com/privasphere/privalope-common/-/issues/765
    * See issue for details on setting version
    */
    static getPdfSignerVersion() {
        let ver = constVar.DISCRETE_PDF_SIGNER_STABLE;
        return ver;
    }
}
Utils.logger = LoggerFactory.getLogger(Utils.name);
