/**
 * @author gudiskis PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 */
import errorXML from "../XMLData/errors.json";
import notificationsXML from "../XMLData/notifications.json";
import { LoggerFactory } from "myd-commons";
import { getCurrentLanguageTS, replaceInputStrings } from "./languages/LanguageFormat";
export class XMLParser {
    constructor() {
        this.errorsList = [];
        this.notificationsList = [];
        this.logger = LoggerFactory.getLogger(XMLParser.name);
        this.parseJson(errorXML, this.errorsList);
        this.parseJson(notificationsXML, this.notificationsList);
    }
    parseJson(jsonData, xmlList) {
        try {
            const xmlDataAsArray = Array.isArray(jsonData) ? jsonData : [jsonData];
            for (const xmlData of xmlDataAsArray) {
                if ((xmlData.frontEnd && xmlData.frontEnd[0][getCurrentLanguageTS()][0]) ||
                    (xmlData.text && xmlData.text[0][getCurrentLanguageTS()][0])) {
                    this.parseXmlData(xmlData, xmlList);
                }
            }
        }
        catch (err) {
            this.logger.error('Error parsing JSON:', err);
        }
    }
    parseXmlData(xmlData, xmlList) {
        const infoObject = {
            property: xmlData.$.property,
            constant: xmlData.$.constant,
            errNo: xmlData.$.errNo,
            err: xmlData.frontEnd ? xmlData.frontEnd[0][getCurrentLanguageTS()][0] : xmlData.text[0][getCurrentLanguageTS()][0],
        };
        xmlList.push(infoObject);
    }
    getErrorByProperty(errProp) {
        return this.errorsList.find(error => error.property === errProp);
    }
    getErrorByPropertyPassValues(errProp, values) {
        let error = this.getErrorByProperty(errProp);
        return this.replaceInputStrings(error, values);
    }
    getNotificationByProperty(notifProp) {
        return this.notificationsList.find(notif => notif.property === notifProp);
    }
    getNotificationByPropertyPassValues(notifProp, values) {
        let notification = this.getNotificationByProperty(notifProp);
        return this.replaceInputStrings(notification, values);
    }
    replaceInputStrings(error, values) {
        if (error) {
            error.err = replaceInputStrings(error.err, values);
        }
        return error;
    }
}
