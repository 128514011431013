/**
 * @author gudiskis PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 *
 */
import { translationCurrent } from "../languages/LangueFormat";
import constVar from "../../../angular/src/assets/const.json";
var maxSizeOfSmallFiles = constVar["MAX_SIZE_OF_SMALL_FILES"];
export function bytesToBestFit(bytes) {
    let result;
    let unit;
    if (bytes >= Math.pow(1024, 3)) {
        result = bytes / Math.pow(1024, 3);
        unit = 'GB';
    }
    else if (bytes >= Math.pow(1024, 2)) {
        result = bytes / Math.pow(1024, 2);
        unit = 'MB';
    }
    else if (bytes >= 1024) {
        result = bytes / 1024;
        unit = 'KB';
    }
    else {
        result = bytes;
        unit = 'Bytes';
    }
    result = Math.floor(result * 10) / 10;
    return `${result} ${unit}`;
}
export function formatBytes(bytes) {
    // Add commas for thousands separator
    const formattedBytes = bytes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${formattedBytes} Bytes`;
}
export function sizeOfAllFiles(files) {
    let sum = 0;
    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (!file)
            continue;
        sum += files[i].size;
    }
    return sum;
}
export function getHiddenInputs() {
    const inputs = document.getElementsByTagName('input');
    const hiddenInputs = [];
    for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        const style = window.getComputedStyle(input);
        if (style.display === 'none' && input.type === 'file') {
            hiddenInputs.push(input);
        }
    }
    return hiddenInputs;
}
export function fileArrayToDataTransfer(fileArray) {
    let newFiles = new DataTransfer();
    for (let i = 0; i < fileArray.length; i++) {
        newFiles.items.add(fileArray[i]);
    }
    return newFiles;
}
export function fileTooBigErrMsg(fName, niceTotal, niceMax) {
    let ts_attachment = translationCurrent.ts_attachment;
    let ts_available = translationCurrent.ts_available;
    let ts_TooBig = translationCurrent.ts_TooBig;
    return ` ${ts_TooBig}: ${ts_attachment} \' ${fName} \' > ${niceTotal} (${ts_available}: ${niceMax}) ! `;
}
export function allFilesToBigErrMsg(niceTotal, niceMax) {
    let ts_attachments = translationCurrent.ts_attachment;
    let ts_available = translationCurrent.ts_available;
    let ts_TooBig = translationCurrent.ts_TooBig;
    let ts_cumulated = translationCurrent.ts_cumulated;
    return ` ${ts_TooBig}: ${ts_attachments} ${ts_cumulated} >${niceTotal} (${ts_available}: ${niceMax}) ! `;
}
export function extNotAllowedErrMsg(ext, allowedExtsHere) {
    let ts_fileNameExtension = translationCurrent.ts_fileNameExtension;
    let ts_instead = translationCurrent.ts_instead;
    return `${ts_fileNameExtension}: "${ext}" ${ts_instead} "${allowedExtsHere}" `;
}
export function humanFileSiz(bytes, si) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + '\u00a0' + 'B';
    }
    var units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    let commaPos = 0;
    if (10 > bytes) {
        commaPos = 1;
    }
    return bytes.toFixed(commaPos) + '\u00a0' + units[u];
}
export function extInList(ext, extList) {
    return (',' + extList + ',').indexOf(',' + ext + ',');
}
export function getFileExtension(fName) {
    let lastDotPos = fName.lastIndexOf(".");
    if (-1 != lastDotPos) {
        return fName.substr(lastDotPos + 1);
    }
    return "";
}
export function isAnyFileLargerThan15MB(fileList) {
    if (null == fileList) {
        return true;
    }
    for (let i = 0; i < fileList.length; i++) {
        let fileSize = fileList[i].size;
        if (fileSize > maxSizeOfSmallFiles) {
            return true;
        }
    }
    return false;
}
export function getSumOfSmallFilesSize(files) {
    let sum = 0;
    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (file.size < maxSizeOfSmallFiles) {
            sum += file.size;
        }
    }
    return sum;
}
export function copyFileList(fileList) {
    let newFileList = new DataTransfer();
    // Add each file individually to the new DataTransfer object
    for (let j = 0; j < fileList.length; j++) {
        let temp = fileList.item(j);
        if (temp) {
            newFileList.items.add(temp);
        }
    }
    return newFileList.files;
}
