/**
 * @author gudiskis PrivaSphere confidential, (c) 2023 - 2024 all rights reserved
 *
 * https://git.privasphere.com/privasphere/privalope-common/-/issues/541
 *
 * Thesaurus auto-upload autoUpload
 *
 */
import { Utils } from "../Utils";
import constVar from "../../../angular/src/assets/const.json";
import { CustomUploadBtn } from "../CustomUploadBtn";
import { LoggerFactory } from "myd-commons";
import { createEyeIcon, createFileListElement, createFileRowCreateprivalope, createFileRowPrepaid, createMaxSizeElement, createSizeAcronym, createTrashCanImg, displayErrorMessage, removeEyeIcon, removeTrashCanImg, sizeAcronym } from "./uiUpdates";
import { allFilesToBigErrMsg, bytesToBestFit, copyFileList, extInList, extNotAllowedErrMsg, fileArrayToDataTransfer, fileTooBigErrMsg, formatBytes, getFileExtension, getSumOfSmallFilesSize, isAnyFileLargerThan15MB, sizeOfAllFiles } from "./utilsFile";
import { translationCurrent } from "../languages/LanguageFormat";
import { FakeFile } from "./FakeFile";
export { existingFiles };
var existingFiles = {};
var forceLargeUploadOfSmallFiles = false;
var sizeOfSmallFiles = {};
export var showFileList = {};
var maxSizeOfSmallFiles = constVar["MAX_SIZE_OF_SMALL_FILES"];
var startingSize = -1;
const logger = LoggerFactory.getLogger("AutoUpload2");
var sizeInServer = 0;
// Expose the function in the global scope
export function handleFileUploadDefault(input) {
    handleFileUpload(input, null, null, null, null);
}
export function handleFileUploadSetAllowedExtensionsAndSize(input, allowedExtensions, maxFileSizeMB, maxAllFilesMB) {
    allowedExtensions = addCommas(allowedExtensions);
    handleFileUpload(input, allowedExtensions, null, maxFileSizeMB, maxAllFilesMB);
}
export function handleFileUploadSetBlockedExtensions(input, blockedExtensions) {
    blockedExtensions = addCommas(blockedExtensions);
    handleFileUpload(input, null, blockedExtensions, null, null);
}
export function handleFileUploadSetAllowedExtensions(input, allowedExtensions) {
    allowedExtensions = addCommas(allowedExtensions);
    handleFileUpload(input, allowedExtensions, null, null, null);
}
function addCommas(exts) {
    if (!exts || 0 >= exts.length) {
        return exts;
    }
    if ("," !== exts.substring(0, 1) && "," !== exts.substring(exts.length - 1, exts.length)) {
        exts = "," + exts + ",";
        return exts;
    }
    if ("," !== exts.substring(0, 1) && "," === exts.substring(exts.length - 1, exts.length)) {
        exts = "," + exts;
        return exts;
    }
    if ("," === exts.substring(0, 1) && "," !== exts.substring(exts.length - 1, exts.length)) {
        exts = exts + ",";
        return exts;
    }
    return exts;
}
export function handleFileUpload(input, allowedExtensions, blockedExtensions, maxFileSizeMB, maxAllFilesMB) {
    logger.info("in handleFileUpload");
    let arrayFileElement = fileUpload(input, allowedExtensions, blockedExtensions, maxFileSizeMB, maxAllFilesMB);
    if (!arrayFileElement) {
        return;
    }
    let files = arrayFileElement ? arrayFileElement[0] : null;
    let fileListDiv = arrayFileElement ? arrayFileElement[1] : null;
    if (!files || !fileListDiv) {
        return;
    }
    if (!input) {
        logger.warn("Input element not found");
        return;
    }
    hideAndDeleteFiles(files, input, () => handleFileUpload(input, null, null, null, null));
    for (let i = 0; i < files.length && (showFileList[input.id] || undefined == showFileList[input.id]); i++) {
        let file = files.item(i);
        if (!file) {
            continue;
        }
        createFileRowPrepaid(file, input, i, fileListDiv);
    }
    maxAllFilesMB = calculateMaxAllFilesMB(maxAllFilesMB, input);
}
function calculateMaxAllFilesMB(maxAllFilesMB, input) {
    if (!maxAllFilesMB) {
        maxAllFilesMB = msgMaxSize;
    }
    let curSize = getSizeOfAllFiles() + parseInt(sessionStorage.getItem('sizeInServer') || '0', 10);
    let fileSizeAcronym = document.getElementById(input.id + sizeAcronym);
    if (!fileSizeAcronym) {
        createMaxSizeElement(input, curSize, maxAllFilesMB);
    }
    const elements = document.querySelectorAll(`[id$="` + sizeAcronym + `"]`);
    for (let el of elements) {
        let acronym1 = createSizeAcronym(el.id.replace(sizeAcronym, ""), curSize);
        el.replaceWith(acronym1);
    }
    return maxAllFilesMB;
}
export function showLargeFiles() {
    for (let [elementID, files] of Object.entries(existingFiles)) {
        let input = Utils.getElementInput(elementID);
        let isHidden = false;
        if (null != showFileList[elementID] && undefined != showFileList[elementID]) {
            isHidden = !showFileList[elementID];
        }
        if (input && isHidden) {
            showFileList[elementID] = true;
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                if (file.size > maxSizeOfSmallFiles) {
                    createFileRowPrepaid(file, input, i, createFileListElement(elementID));
                }
            }
        }
    }
}
function hideAndDeleteFiles(files, input, handleUpload) {
    if (files.length >= 5) {
        let div = createEyeIcon(input, handleUpload);
        if (div) {
            createTrashCanImg(input, div, handleUpload);
        }
    }
    else {
        removeEyeIcon(input);
        removeTrashCanImg(input.id);
    }
}
export function handleCreateprivalopeFileUpload(input, allowedExtensions, blockedExtensions, maxFileSizeMB, maxAllFilesMB) {
    var _a;
    let arrayFileElement = fileUpload(input, allowedExtensions, blockedExtensions, maxFileSizeMB, maxAllFilesMB);
    if (!arrayFileElement) {
        return;
    }
    let files = arrayFileElement ? arrayFileElement[0] : null;
    let fileListDiv = arrayFileElement ? arrayFileElement[1] : null;
    if (!files || !fileListDiv) {
        return;
    }
    if (!input) {
        logger.info("Input element not found");
        return;
    }
    hideAndDeleteFiles(files, input, () => handleCreateprivalopeFileUpload(input, null, null, null, null));
    for (let i = 0; i < files.length && (showFileList[input.id] || undefined == showFileList[input.id]); i++) {
        let file = files[i];
        createFileRowCreateprivalope(file, input, i, fileListDiv);
    }
    const maxSizeElement = document.getElementById('maxSize');
    if (maxSizeElement) {
        const firstAcronym = maxSizeElement.querySelector('acronym');
        const secondAcronym = (_a = firstAcronym === null || firstAcronym === void 0 ? void 0 : firstAcronym.nextElementSibling) === null || _a === void 0 ? void 0 : _a.nextElementSibling;
        if (firstAcronym && secondAcronym) {
            if (startingSize === -1) {
                startingSize = +firstAcronym.title.replace(/\D/g, '');
            }
            let curSize = startingSize + sizeOfAllFiles(files);
            firstAcronym.title = formatBytes(curSize);
            firstAcronym.textContent = bytesToBestFit(curSize);
        }
        else {
            logger.info('No <acronym> element found within the element with ID "maxSize"');
        }
        let eyeDiv = document.getElementById("eyeDiv_" + input.id);
        if (eyeDiv) {
            eyeDiv.appendChild(maxSizeElement);
        }
        else {
            logger.info('Element with ID "eyeDiv_" + input.id not found');
        }
    }
    else {
        logger.info('Element with ID "maxSize" not found');
    }
    let uplDiv = document.getElementById("uplDiv");
    let secConWidth = document.getElementById("secConWidth");
    if (!isAnyFileLargerThan15MB(input.files) && !getForceLargeUploadOfSmallFiles()) {
        if (uplDiv) {
            uplDiv.style.display = "none";
        }
        if (secConWidth) {
            secConWidth.style.display = "none";
        }
    }
}
function fileUpload(input, allowedExtensions, blockedExtensions, maxFileSizeMB, maxAllFilesMB) {
    if (!input) {
        return null;
    }
    if (typeof currentLanguageTS === 'undefined') {
        logger.error("currentLanguageTS is not defined. Please add 'sd_jsUploadFunctionsEtc', 'sd_dropAtta4772javaScript' and 'jsUploadFunctionsEtc' macros.");
        return null;
    }
    if (!allowedExtensions) {
        if (typeof permittedExts === 'undefined') {
            logger.error("permittedExts is not defined. Please add 'sd_jsUploadFunctionsEtc', 'sd_dropAtta4772javaScript' and 'jsUploadFunctionsEtc' macros.");
            return null;
        }
        allowedExtensions = permittedExts;
    }
    if (!blockedExtensions) {
        blockedExtensions = "";
    }
    if (!maxFileSizeMB) {
        if (typeof singleAttaMaxSize === 'undefined') {
            logger.error("singleAttaMaxSize is not defined. Please add 'sd_jsUploadFunctionsEtc', 'sd_dropAtta4772javaScript' and 'jsUploadFunctionsEtc' macros.");
            return null;
        }
        maxFileSizeMB = singleAttaMaxSize / (1024 * 1024);
    }
    if (!maxAllFilesMB) {
        if (typeof msgMaxSize === 'undefined') {
            logger.error("msgMaxSize is not defined. Please add 'sd_jsUploadFunctionsEtc', 'sd_dropAtta4772javaScript' and 'jsUploadFunctionsEtc' macros.");
            return null;
        }
        maxAllFilesMB = msgMaxSize / (1024 * 1024);
    }
    let fileListDiv = createFileListElement(input.id);
    if (!fileListDiv) {
        logger.warn("File list element " + "fileList_" + input.id + " not found");
        return null;
    }
    // Clear previous file rows
    fileListDiv.innerHTML = "";
    let files = input.files;
    if (!files) {
        logger.warn("No files found");
        return null;
    }
    CustomUploadBtn.removeUploadFieldErr(input.id);
    //validate files and copy only legal files
    let legalFiles = new DataTransfer();
    let totalExistingFilesSize = getSizeOfAllFiles();
    let oldFileList = document.getElementById("brwsBtnRltList-" + input.id);
    if (oldFileList) {
        oldFileList.style.display = "none";
    }
    let staticFile = document.getElementById("fileListInBackEnd_" + input.id);
    if (staticFile && !input.multiple) {
        displayErrorMessage(translationCurrent.ts_fileAlreadyExists, fileListDiv, input.id);
        return null;
    }
    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (existingFiles[input.id] && existingFiles[input.id].some(item => item.name === file.name)) {
            totalExistingFilesSize -= file.size;
        }
        let errorMessage = validateFile(file, allowedExtensions, blockedExtensions, maxFileSizeMB, maxAllFilesMB, totalExistingFilesSize);
        if (null == errorMessage) {
            legalFiles.items.add(file);
            //add file size only if the file is not in existingFiles
            if (!existingFiles[input.id] || !existingFiles[input.id].some(item => item.name === file.name)) {
                totalExistingFilesSize += file.size;
            }
        }
        else {
            displayErrorMessage(errorMessage, fileListDiv, input.id);
            if (maxAllFilesMB && file.size + totalExistingFilesSize > maxAllFilesMB * 1024 * 1024) {
                break;
            }
        }
    }
    input.files = legalFiles.files;
    files = input.files;
    checkExistingFiles(input.id, allowedExtensions, blockedExtensions, maxFileSizeMB, maxAllFilesMB, totalExistingFilesSize);
    logFileListDetails(files, "Selected files to upload via browser");
    if (!input.multiple) {
        //handle inputs where only one file is allowed
        if (1 < files.length) {
            displayErrorMessage(translationCurrent.ts_onlyOneFileAllowed, fileListDiv, input.id);
            throw (Utils.getCurrentTimeString() + files.length + " files was added to input (" + input.id + ") that supports only one");
        }
        if (files.length === 0 && existingFiles[input.id] && existingFiles[input.id].length > 0) {
            input.files = copyFileList(fileArrayToDataTransfer(existingFiles[input.id]).files);
        }
        else {
            existingFiles[input.id] = Array.from(input.files);
        }
    }
    else {
        //handle inputs where multiple files are allowed
        let copyFiles = addFilesToInputRow(input.id, files);
        if (copyFiles) {
            input.files = copyFiles;
        }
        else {
            existingFiles[input.id] = Array.from(input.files);
        }
    }
    forceLargeUploadOfSmallFiles = isForceLargeUploadOfSmallFiles(existingFiles[input.id], input.id);
    CustomUploadBtn.applyChangesToCustomBrowse(input.id, input.id + "Span");
    return [input.files, fileListDiv];
}
function logFileListDetails(files, purposeOfTheLog = "") {
    if (!files || files.length === 0) {
        logger.info("No files selected.");
        return;
    }
    Array.from(files).forEach(file => {
        logFileDetails(file, purposeOfTheLog);
    });
}
function logFileDetails(file, purposeOfTheLog = "") {
    const name = file.name;
    const size = file.size;
    const format = file.type || "Unknown format";
    logger.info("-----------------------");
    logger.info(`File Name: ${name}`);
    if (purposeOfTheLog && "" != purposeOfTheLog) {
        logger.info(`Purpose of the log: ${purposeOfTheLog}`);
    }
    logger.info(`File Size: ${bytesToBestFit(size)}`);
    logger.info(`File Format: ${format}`);
    logger.info("-----------------------");
}
export function getSizeOfAllFiles() {
    return Object.values(existingFiles).flat().reduce((total, file) => total + file.size, 0);
}
export function deleteAllFiles(inputID) {
    let input = Utils.getElementInput(inputID);
    if (!input) {
        logger.warn("Input element not found");
        return;
    }
    var newFiles = new DataTransfer();
    input.files = newFiles.files;
    existingFiles[inputID] = [];
}
function isForceLargeUploadOfSmallFiles(files, inputID) {
    if (!files) {
        logger.error("No files found");
        throw new Error("AutoUpload2: No files found");
    }
    let sum = getSumOfSmallFilesSize(files);
    sizeOfSmallFiles[inputID] = sum;
    let keys = Object.keys(sizeOfSmallFiles);
    let sumOfAllSmallFiles = keys.reduce((total, key) => total + sizeOfSmallFiles[key], 0);
    //if the sum of all small files is greater then 120MB, then force large upload of small files
    let maxSizeOfAllSmallFiles = constVar["TOMCAT_SMALL_FILE_LIMIT"];
    if (sumOfAllSmallFiles > maxSizeOfAllSmallFiles) {
        logger.info("isForceLargeUploadOfSmallFiles: sumOfAllSmallFiles " + sumOfAllSmallFiles
            + " maxSizeOfAllSmallFiles " + maxSizeOfAllSmallFiles + " " + files.length + " " + new Date().toISOString());
        return true;
    }
    else {
        return false;
    }
}
export function validateFile(file, allowedExtensions, blockedExtensions, maxFileSizeMB, maxAllFilesMB, totalExistingFilesSize) {
    allowedExtensions = allowedExtensions.toLowerCase();
    blockedExtensions = blockedExtensions.toLowerCase();
    if (maxFileSizeMB && file.size > maxFileSizeMB * 1024 * 1024) {
        return fileTooBigErrMsg(file.name, bytesToBestFit(file.size), bytesToBestFit(maxFileSizeMB * 1024 * 1024));
    }
    if (maxAllFilesMB && file.size + totalExistingFilesSize > maxAllFilesMB * 1024 * 1024) {
        return allFilesToBigErrMsg(bytesToBestFit(totalExistingFilesSize + file.size), bytesToBestFit(maxAllFilesMB * 1024 * 1024));
    }
    let fileExtension = getFileExtension(file.name).toLowerCase();
    if (allowedExtensions && allowedExtensions.length > 0 &&
        "," === allowedExtensions.substring(0, 1) &&
        "," === allowedExtensions.substring(allowedExtensions.length - 1, allowedExtensions.length)) {
        let indexOfAllowed = extInList(fileExtension, allowedExtensions);
        if (indexOfAllowed === -1) {
            let allowedExtensionsPresentable = allowedExtensions.substring(1, allowedExtensions.length - 1);
            return extNotAllowedErrMsg(fileExtension, allowedExtensionsPresentable);
        }
    }
    if (blockedExtensions && blockedExtensions.length > 0 &&
        "," === blockedExtensions.substring(0, 1) &&
        "," === blockedExtensions.substring(blockedExtensions.length - 1, blockedExtensions.length)) {
        let indexOfBlocked = extInList(fileExtension, blockedExtensions);
        if (indexOfBlocked !== -1) {
            let blockedExtensionsPresentable = blockedExtensions.substring(1, blockedExtensions.length - 1);
            return extNotAllowedErrMsg(fileExtension, blockedExtensionsPresentable);
        }
    }
    return null;
}
function checkExistingFiles(inputID, allowedExtensions, blockedExtensions, maxFileSizeMB, maxAllFilesMB, totalExistingFilesSize) {
    let oldFiles = existingFiles[inputID];
    if (!oldFiles) {
        return;
    }
    let newFilesArray = new DataTransfer();
    for (let i = 0; i < oldFiles.length; i++) {
        let errorMessage = validateFile(oldFiles[i], allowedExtensions, blockedExtensions, maxFileSizeMB, maxAllFilesMB, totalExistingFilesSize - oldFiles[i].size);
        if (null == errorMessage) {
            newFilesArray.items.add(oldFiles[i]);
        }
    }
    existingFiles[inputID] = Array.from(newFilesArray.files);
}
export function addFilesToInputRow(inputID, files) {
    if (!existingFiles[inputID]) {
        return null;
    }
    let newFiles = fileArrayToDataTransfer(existingFiles[inputID]);
    let newFilesArray = Array.from(newFiles.files);
    for (let i = 0; i < files.length; i++) {
        if (newFilesArray && files) {
            let file = files.item(i);
            if ((file != null) && !newFilesArray.find(item => (item === null || item === void 0 ? void 0 : item.name) === (file === null || file === void 0 ? void 0 : file.name))) {
                newFiles.items.add(file);
                existingFiles[inputID] = Array.from(newFiles.files);
            }
        }
    }
    return copyFileList(newFiles.files);
}
export function deleteFile(inputID, fileIndex) {
    deleteFileObject(inputID, fileIndex);
    let input = Utils.getElementInput(inputID);
    handleFileUpload(input, null, null, null, null);
}
export function deleteFileCreteprivalope(inputID, fileIndex) {
    deleteFileObject(inputID, fileIndex);
    let input = Utils.getElementInput(inputID);
    handleCreateprivalopeFileUpload(input, null, null, null, null);
}
//get file ID in existingFiles array by passing the file
export function getElementIdByFile(searchFile) {
    for (let [elementID, files] of Object.entries(existingFiles)) {
        if (files.some(file => file.name === searchFile.name && file.size === searchFile.size && file.type === searchFile.type)) {
            let fileIndex = files.findIndex(file => file.name === searchFile.name);
            return elementID + "_" + fileIndex;
        }
    }
    return null;
}
export function deleteFileObject(inputID, fileIndex) {
    let input = Utils.getElementInput(inputID);
    if (!input) {
        logger.warn("Input element not found");
        return null;
    }
    let files = input.files;
    if (!files) {
        logger.warn("No files found");
        return null;
    }
    // Remove the file from the list
    let newFiles = new DataTransfer();
    for (let i = 0; i < files.length; i++) {
        if (i !== fileIndex) {
            newFiles.items.add(files[i]);
        }
    }
    input.files = copyFileList(newFiles.files);
    existingFiles[inputID] = Array.from(copyFileList(newFiles.files));
    // needed for event triggering on deletion
    let event = new Event('change');
    input.dispatchEvent(event);
    return;
}
export function getForceLargeUploadOfSmallFiles() {
    return forceLargeUploadOfSmallFiles;
}
export function virusDetected(fileName) {
    if (!fileName || "" === fileName) {
        logger.warn("found virus but can't get the file name");
        return;
    }
    for (let [elementID, files] of Object.entries(existingFiles)) {
        for (let i = 0; i < files.length; i++) {
            if (fileName === files[i].name) {
                logger.warn("found and blocked virus:");
                logFileDetails(files[i], "virus found in file");
                deleteFileCreteprivalope(elementID, i);
                CustomUploadBtn.setUploadFieldToErr(elementID);
                break;
            }
        }
    }
}
export function getExistingFiles() {
    return existingFiles;
}
function getMimeType(parent) {
    const acronym = parent.querySelector('acronym[title^="MIME-Type"]');
    if (acronym) {
        return acronym.title.replace("MIME-Type: '", "").replace("'", "");
    }
    return null;
}
function getFileName(parent) {
    const a = parent.querySelector('a');
    if (a) {
        return a.textContent;
    }
    return null;
}
function getFileSize(parent) {
    const acronym = parent.querySelector('acronym[title*="Bytes"]');
    if (acronym) {
        const sizeString = acronym.title.replace(/,/g, '').replace('Bytes', '');
        try {
            return parseInt(sizeString);
        }
        catch (error) {
            logger.error("Error parsing file size: " + sizeString);
        }
    }
    return null;
}
export function getFilesFromMailHead() {
    let savedFiles = document.querySelectorAll("input[type='submit'][fileinput]");
    if (!savedFiles || 0 === savedFiles.length)
        return;
    for (let i = 0; i < savedFiles.length; i++) {
        let fileInput = savedFiles[i];
        let parent = fileInput.parentElement;
        if (!parent)
            continue;
        let mime = getMimeType(parent);
        let fileName = getFileName(parent);
        let fileSize = getFileSize(parent);
        if (!mime || !fileName || !fileSize)
            continue;
        const fakeFile = new FakeFile(fileName, mime, fileSize);
        let fileNumber = fileInput.getAttribute('fileinput');
        if (!fileNumber || 0 == fileNumber.length)
            continue;
        let inputs = document.querySelectorAll(`input[name='${fileNumber}'][type='file']`);
        if (!inputs || 0 === inputs.length)
            continue;
        let input = inputs[0];
        let div = createFileListElement(input.id, "fileListInBackEnd_");
        if (!div)
            continue;
        function clickSavedFiles(inputID, i) {
            div.remove();
            fileInput.click();
        }
        sizeInServer += fileSize;
        calculateMaxAllFilesMB(0, input);
        removeRequired(input);
        createFileRowPrepaid(fakeFile, input, i, div, clickSavedFiles);
    }
    sessionStorage.setItem('sizeInServer', sizeInServer.toString());
}
function removeRequired(input) {
    input.removeAttribute("required");
    input.removeAttribute("aria-required");
    input.classList.remove("require", "requireByJS");
}
