/**
 * @author gudiskis PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 *
 */
import { LoggerFactory } from "myd-commons";
import { CustomUploadBtn } from "../CustomUploadBtn";
import { Utils } from "../Utils";
import constVar from "../../../angular/src/assets/const.json";
import { deleteAllFiles, deleteFile, deleteFileCreteprivalope, showFileList } from "./AutoUpload2";
import { bytesToBestFit, formatBytes, getHiddenInputs } from "./utilsFile";
import { translationCurrent } from "../languages/LangueFormat";
const logger = LoggerFactory.getLogger("AutoUpload2 UI Updates");
export const sizeAcronym = "SizeAcronym";
var maxSizeOfSmallFiles = constVar["MAX_SIZE_OF_SMALL_FILES"];
export function updateUploadBtnInformationAndSizeLimit() {
    const hiddenInputs = getHiddenInputs();
    for (let i = 0; i < hiddenInputs.length; i++) {
        const input = hiddenInputs[i];
        let extensionsMessage = extractAllowedExtensions(input);
        if ("" === extensionsMessage && 0 < permittedExts.length) {
            extensionsMessage = permittedExts.toLowerCase().substring(1, permittedExts.length - 1);
        }
        let fileSizeAcronym = document.getElementById(input.id + sizeAcronym);
        if (!fileSizeAcronym) {
            createMaxSizeElement(input, 0, msgMaxSize);
        }
        const uploadButton = document.getElementById(input.id + CustomUploadBtn.browseBtn);
        if (!uploadButton)
            continue;
        uploadButton.parentElement.title = `${translationCurrent.sd_PermittedExtensions}: `
            + extensionsMessage;
    }
}
function extractAllowedExtensions(input) {
    if (input.onchange) {
        const onchangeStr = input.onchange.toString();
        const regex = /handleFileUploadSetAllowedExtensions(?:AndSize)?\((?:this, )?`([^`]*)`/;
        const match = onchangeStr.match(regex);
        if (match && match[1]) {
            let allowedExt = match[1];
            logger.info(`Allowed extensions: ${allowedExt}`);
            return allowedExt;
        }
    }
    else {
        logger.warn("No onchange event found. The inptu element may not be properly initialized.");
    }
    return "";
}
export function createMaxSizeElement(input, curSize, maxFileSizeMB) {
    if (!input || !input.parentElement) {
        logger.warn("Input element not found");
        return;
    }
    const maxSizeSpan = document.createElement("span");
    const textNode1 = document.createTextNode("(");
    maxSizeSpan.appendChild(textNode1);
    const acronym1 = createSizeAcronym(input.id, curSize);
    maxSizeSpan.appendChild(acronym1);
    const textNode2 = document.createTextNode(` ${translationCurrent.sd_of} `);
    maxSizeSpan.appendChild(textNode2);
    const link = document.createElement("a");
    link.href = Utils.createHelpLink("ATTA_TOO_BIG");
    link.title = translationCurrent.sd_moreInfo;
    link.target = "_blank";
    link.className = "hlp";
    link.textContent = "max.";
    maxSizeSpan.appendChild(link);
    const acronym2 = document.createElement("acronym");
    acronym2.title = formatBytes(maxFileSizeMB);
    acronym2.textContent = bytesToBestFit(maxFileSizeMB);
    maxSizeSpan.appendChild(acronym2);
    const textNode3 = document.createTextNode(" ");
    maxSizeSpan.appendChild(textNode3);
    const acronym3 = document.createElement("acronym");
    acronym3.title =
        `eGov-${translationCurrent.sd_registeredTitlePrefix} ${translationCurrent.sd_interoperable}: 15MB ${translationCurrent.sd_orLess}`;
    acronym3.innerHTML = "&nbsp;";
    maxSizeSpan.appendChild(acronym3);
    const textNode4 = document.createTextNode(")");
    maxSizeSpan.appendChild(textNode4);
    input.parentElement.appendChild(maxSizeSpan);
}
export function createSizeAcronym(ID, curSize) {
    const acronym1 = document.createElement("acronym");
    acronym1.id = ID + sizeAcronym;
    acronym1.title = formatBytes(curSize);
    acronym1.textContent = bytesToBestFit(curSize);
    return acronym1;
}
export function removeTrashCanImg(inputID) {
    let trashImg = document.getElementById("trash_icon_delete_" + inputID);
    if (trashImg) {
        trashImg.remove();
    }
}
export function createTrashCanImg(input, div, handleUpload) {
    let trashImg = document.getElementById("trash_icon_delete_" + input.id);
    if (trashImg || div === null) {
        return;
    }
    let img = document.createElement("img");
    img.src = "/images/psph/trash.gif";
    img.id = "trash_icon_delete_" + input.id;
    img.alt = translationCurrent.ts_deleteAllFiles;
    img.title = translationCurrent.ts_deleteAllFiles;
    img.style.cursor = "pointer";
    img.style.paddingLeft = "10px";
    img.style.paddingRight = "10px";
    img.style.width = "1.1em";
    img.onclick = function () {
        showFileList[input.id] = true;
        deleteAllFiles(input.id);
        handleUpload();
    };
    div.appendChild(img);
}
export function displayErrorMessage(message, fileInfoDiv, inputID) {
    createClearBothDiv(fileInfoDiv);
    const errorDiv = document.createElement("div");
    errorDiv.className = "error-row";
    errorDiv.style.maxWidth = "100%";
    errorDiv.textContent = message;
    fileInfoDiv.appendChild(errorDiv);
    CustomUploadBtn.setUploadFieldToErr(inputID);
}
export function createUploadBar(divBorder, fileSpan, fileInfoDiv, input, i) {
    appendFileDivToUploadDiv(divBorder, fileSpan, fileInfoDiv);
    let dataProgress = document.createElement("div");
    dataProgress.id = "dataProgress_" + input.id + "_" + i;
    fileInfoDiv.appendChild(dataProgress);
    let barDiv = document.createElement("div");
    barDiv.style.display = "block";
    barDiv.id = "bar_" + input.id + "_" + i;
    fileInfoDiv.appendChild(barDiv);
}
export function createFileRowPrepaid(file, input, i, fileListDiv) {
    let { deleteInput, fileSpan, divBorder, fileInfoDiv } = createFileInfoDiv(fileListDiv, input, i, file);
    deleteInput.onclick = function () {
        deleteFile(input.id, i);
    };
    // Create an acronym for the file size
    addBytesAcronym(file, fileSpan);
    let mimeElement = document.createElement("acronym");
    mimeElement.title = "MIME-Type: " + file.type;
    mimeElement.innerHTML = "&nbsp;";
    fileSpan.appendChild(mimeElement);
    createUploadBar(divBorder, fileSpan, fileInfoDiv, input, i);
    fileListDiv.appendChild(fileInfoDiv);
}
export function appendFileDivToUploadDiv(divBorder, fileSpan, fileInfoDiv) {
    divBorder.appendChild(fileSpan);
    fileInfoDiv.appendChild(divBorder);
}
export function addBytesAcronym(file, fileSpan) {
    let space = document.createElement("acronym");
    space.innerHTML = "&nbsp;&nbsp;";
    fileSpan.appendChild(space);
    let sizeAcronym = document.createElement("acronym");
    sizeAcronym.title = file.size + " Bytes";
    sizeAcronym.innerText = bytesToBestFit(file.size);
    sizeAcronym.style.fontStyle = "italic";
    fileSpan.appendChild(sizeAcronym);
}
export function createFileInfoDiv(fileListDiv, input, i, file) {
    createClearBothDiv(fileListDiv);
    // Create a div with class "autUploadFile"
    let fileInfoDiv = document.createElement("div");
    fileInfoDiv.className = "autUploadFile";
    fileInfoDiv.id = "autUploadFile_" + input.id + "_" + i;
    if (file.size > maxSizeOfSmallFiles) {
        fileInfoDiv.className += " " + "LgOrange";
    }
    // Create an input element for deletion
    let deleteInput = document.createElement("input");
    deleteInput.type = "submit";
    deleteInput.className = "cssbutton txtBtn attDelBtn";
    deleteInput.value = translationCurrent.ts_Delete;
    deleteInput.title = translationCurrent.ts_Delete + " " + file.name;
    fileInfoDiv.appendChild(deleteInput);
    let divBorder = document.createElement("div");
    divBorder.classList.add("file-name-border");
    if (file.size > maxSizeOfSmallFiles) {
        // Create and append the new span with class "lgLabel"
        let largeLabelSpan = document.createElement("span");
        largeLabelSpan.className = "lgLabel";
        largeLabelSpan.innerText = "LARGE";
        divBorder.appendChild(largeLabelSpan);
        // Create and append the new link with the help icon
        let helpLink = document.createElement("a");
        helpLink.href = Utils.createHelpLink("LARGE_1597");
        helpLink.style.marginRight = "10px";
        let moreInfo = translationCurrent.ts_moreInfo;
        let inANewWindow = translationCurrent.ts_in_aNewWindow;
        let helpLinkTitle = moreInfo + " : Secure Large File Transfer - rfe1597 (Beta)";
        helpLink.title = helpLinkTitle + " (" + inANewWindow + ")";
        helpLink.target = "_blank";
        helpLink.className = "hlp";
        let helpIcon = document.createElement("img");
        helpIcon.src = "/images/psph/help.gif";
        helpIcon.title = helpLinkTitle + " (" + inANewWindow + ")";
        helpIcon.alt = helpLinkTitle;
        helpLink.appendChild(helpIcon);
        divBorder.appendChild(helpLink);
    }
    // Create a span for file information
    let fileSpan = document.createElement("span");
    // Create a link for downloading the file
    let downloadLink = document.createElement("a");
    downloadLink.className = "dragOut5375";
    downloadLink.title = translationCurrent.ts_CheckAttaBeforeDispatch + " - rfe5560: " + file.name;
    downloadLink.style.fontWeight = "bold";
    downloadLink.innerText = " " + file.name + " ";
    fileSpan.appendChild(downloadLink);
    return { deleteInput, fileSpan, divBorder, fileInfoDiv };
}
export function createEyeIcon(input, handleUpload) {
    if ((false == showFileList[input.id] || showFileList[input.id]) && document.getElementById("eyeDiv_" + input.id) !== null) {
        return null;
    }
    showFileList[input.id] = false;
    let div = document.createElement("div");
    div.id = "eyeDiv_" + input.id;
    let aElementDescription = document.createElement("a");
    aElementDescription.textContent = translationCurrent.ts_showFiles;
    aElementDescription.style.paddingRight = "10px";
    div.appendChild(aElementDescription);
    let imgElement = document.createElement("img");
    imgElement.id = "eye_file_list_" + input.id;
    imgElement.src = "/images/eyeNoIris.png";
    imgElement.style.cursor = "pointer";
    imgElement.style.width = "1.8em";
    imgElement.title = translationCurrent.ts_showFiles;
    imgElement.addEventListener("click", () => {
        if (imgElement.src.includes("eyeNoIris.png")) {
            imgElement.src = "/images/eyeWithIris.png";
            aElementDescription.textContent = translationCurrent.ts_hideFiles;
            imgElement.title = translationCurrent.ts_hideFiles;
        }
        else {
            imgElement.src = "/images/eyeNoIris.png";
            aElementDescription.textContent = translationCurrent.ts_showFiles;
            imgElement.title = translationCurrent.ts_showFiles;
        }
        showFileList[input.id] = !showFileList[input.id];
        handleUpload();
    });
    div.appendChild(imgElement);
    //append div to parent after the input element
    if (input.nextSibling) {
        input.parentElement.insertBefore(div, input.nextSibling);
    }
    else {
        input.parentElement.appendChild(div);
    }
    return div;
}
export function createClearBothDiv(fileListDiv) {
    let ts_clearBoth = document.createElement("div");
    ts_clearBoth.style.clear = "both";
    fileListDiv.appendChild(ts_clearBoth);
}
export function removeEyeIcon(input) {
    const maxSizeElement = document.getElementById('maxSize');
    if (maxSizeElement) {
        let eyeDiv = document.getElementById("eyeDiv_" + input.id);
        if (eyeDiv) {
            input.parentElement.insertBefore(maxSizeElement, input.nextSibling);
            eyeDiv.remove();
        }
    }
    let eyeDiv = document.getElementById("eyeDiv_" + input.id);
    if (eyeDiv) {
        eyeDiv.remove();
        delete showFileList[input.id];
    }
}
export function createFileListElement(inputID) {
    let fileListDiv = document.getElementById("fileList_" + inputID);
    // Create a new div element if the file list doesn`t exist yet
    if (!fileListDiv) {
        let fileListElement = document.createElement("div");
        fileListElement.id = "fileList_" + inputID;
        fileListElement.className = "file-info";
        // Find the parent with ID Xr and append the fileListElement
        let inputDiv = document.getElementById(inputID + "r");
        //let inputElement: HTMLElement | null = document.getElementById(inputID);
        //let parentDiv: HTMLElement | null = inputElement ? inputElement.parentElement : null;
        if (!inputDiv) {
            inputDiv = document.getElementById("anhang");
            if (!inputDiv) {
                logger.warn("Input element " + inputID + "r not found");
                return null;
            }
        }
        inputDiv.appendChild(fileListElement);
        fileListDiv = document.getElementById("fileList_" + inputID);
    }
    return fileListDiv;
}
export function createFileRowCreateprivalope(file, input, i, fileListDiv) {
    let { deleteInput, fileSpan, divBorder, fileInfoDiv } = createFileInfoDiv(fileListDiv, input, i, file);
    fileInfoDiv.style.display = "block";
    deleteInput.onclick = function () {
        deleteFileCreteprivalope(input.id, i);
    };
    // Create an acronym for MIME-Type (customize as needed)
    let mimeElementSpan = document.createElement("span");
    mimeElementSpan.className = "mimetype";
    mimeElementSpan.innerText = " " + file.type + " ";
    fileSpan.appendChild(mimeElementSpan);
    let lastModifiedDate = new Date(file.lastModified);
    let lastModifiedDateElement = document.createElement("acronym");
    lastModifiedDateElement.className = "lastModified";
    lastModifiedDateElement.title = lastModifiedDate.toLocaleString();
    const year = lastModifiedDate.getFullYear();
    const month = lastModifiedDate.getMonth() + 1;
    const day = lastModifiedDate.getDate();
    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    lastModifiedDateElement.innerText = " " + formattedDate + " ";
    fileSpan.appendChild(lastModifiedDateElement);
    // Create an acronym for the file size
    addBytesAcronym(file, fileSpan);
    let mimeElementAcronym = document.createElement("acronym");
    mimeElementAcronym.title = "MIME-Type: " + file.type;
    mimeElementAcronym.innerHTML = "&nbsp;";
    fileSpan.appendChild(mimeElementAcronym);
    createUploadBar(divBorder, fileSpan, fileInfoDiv, input, i);
    fileListDiv.appendChild(fileInfoDiv);
}
