/**
 * @author gudiskis PrivaSphere confidential, (c) 2023 - 2024 all rights reserved
 */
import { LoggerFactory } from 'myd-commons';
import { deTranslation } from './LanguageFormat.de';
import { enTranslation } from './LanguageFormat.en';
import { frTranslation } from './LanguageFormat.fr';
import { itTranslation } from './LanguageFormat.it';
const logger = LoggerFactory.getLogger('LanguageFormat');
export const translations = {
    en: enTranslation,
    de: deTranslation,
    fr: frTranslation,
    it: itTranslation
};
export function getCurrentTranslation() {
    return translations[getCurrentLanguageTS()];
}
export function getCurrentLanguageTS() {
    if (typeof currentLanguageTS !== 'undefined') {
        return currentLanguageTS;
    }
    logger.warn(`currentLanguageTS is not set in inline. Defaulting to en`);
    return 'en';
}
export function getTranslation(key) {
    if (key && isSupportedLanguage(key)) {
        return translations[key];
    }
    logger.warn(`Language ${key} is not supported. Defaulting to en`);
    return translations['en'];
}
function isSupportedLanguage(key) {
    return ['en', 'de', 'fr', 'it'].includes(key);
}
export function getTypo3LanguageNumber(currentLanguageTS) {
    switch (currentLanguageTS) {
        case 'en':
            return 0;
        case 'de':
            return 1;
        case 'fr':
            return 3;
        case 'it':
            return 4;
        default:
            return 0;
    }
}
export function replaceInputStrings(text, values) {
    return text.replace(/{\d+}/g, (match) => {
        const index = parseInt(match.substring(1, match.length - 1));
        if (index >= 0 && index < values.length) {
            return values[index];
        }
        return match;
    });
}
export const translationCurrent = getCurrentTranslation();
