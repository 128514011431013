/**
 * @author gudiskis PrivaSphere confidential, (c) 2023 - 2024 all rights reserved
 */
import constVarLan from "../../../angular/src/assets/i18n/it.json";
export const itTranslation = {
    ts_Delete: "Elimina",
    ts_CheckAttaBeforeDispatch: "Verifica l’allegato prima dell’invio",
    ts_TooBig: "Troppo grande",
    ts_attachment: "allegato",
    ts_available: "disponibile",
    ts_cumulated: "cumulated",
    ts_fileNameExtension: "filename-extension",
    ts_instead: "invece di",
    ts_moreInfo: "Visualizza maggiori informazioni",
    ts_in_aNewWindow: "in una nuova finestra",
    ts_MoreThanOneNumberGroupPresent: "È presente più di un gruppo di numeri.",
    ts_NoNumberPresent: "Nessun numero è presente.",
    ts_TooManyDecimalDelimiters: "Troppi delimitatori decimali.",
    ts_TooManyMinusSigns: "Troppi segni meno.",
    ts_LeadingZerosRemoved: "Zeri iniziali rimossi.",
    ts_WhitespaceRemoved: "Spazi vuoti rimossi.",
    ts_CommasReplacedWithDots: "Virgole sostituite con punti.",
    ts_MultipleMinusSignsReducedToOne: "Più segni meno ridotti a uno.",
    ts_MultipleDecimalDelimitersReducedToOne: "Più delimitatori decimali ridotti a solo",
    ts_MultipleCharsReducedToOne: "Varie lettere consecutive ridotte a:",
    ts_NonNumberRemoved: "Caratteri non numerici rimossi (eccetto - e . ).",
    ts_NumberRounded: "Il numero è stato arrotondato.",
    ts_FailedRoundingTheNumber: "Arrotondamento del numero non riuscito.",
    ts_MinusSignNotAllowed: "Il segno meno non è consentito ed è stato rimosso.",
    ts_QuoteRemoved: "Virgolette ('\") rimosse.",
    ts_Input: "Input",
    ts_IsInvalid: "non è valido",
    ts_showFiles: "Mostra file",
    ts_hideFiles: "Nascondi file",
    ts_deleteAllFiles: "Elimina tutti i file",
    sd_noFilesSelected: constVarLan["sd_noFilesSelected"],
    ts_filesSelected: "file da caricare",
    ts_onlyOneFileAllowed: "È consentito solo un file per questo input",
    sd_From: constVarLan["sd_From"],
    sd_to: constVarLan["sd_to"],
    ts_correctGroups: "Rilevati più input di gruppo corretti, scegli uno: ",
    ts_incorrectCharacter: "Carattere errato rilevato: ",
    ts_inputTooLong: "L'input contiene troppi caratteri. Massimo consentito:",
    ts_currently: "Attualmente: ",
    ts_inputTooShort: "L'input contiene troppo pochi caratteri. Minimo richiesto:",
    ts_outOfRangeDate: "La data inserita è fuori dall'intervallo.",
    ts_outOfRangeTooEarly: "è troppo tardi quando il massimo consentito è",
    ts_outOfRangeTooLate: "è troppo presto quando il minimo consentito è",
    ts_noValidDateFound: "La data inserita non è valida.",
    ts_dateChangedTo: "non è una data valida ed è stata cambiata in",
    ts_dateChangedFrom: "La data inserita è stata cambiata da",
    ts_unsupportedDateFormat: "Formato data non supportato",
    ts_parameter: "Parametro",
    sd_Month: constVarLan["sd_Month"],
    sd_Year: constVarLan["sd_Year"],
    sd_day: constVarLan["sd_day"],
    ts_pleaseChange: "Si prega di cambiare",
    ts_toMatchTheFormat: "per corrispondere al formato corretto",
    ts_whitespacesRemoved: "spazi vuoti rimossi",
    ts_whitespaceRemoved: "spazio vuoto rimossi",
    ts_wrongSeparators: "sono separatori errati, il formato corretto è",
    ts_dateFormatChangedTo: "Formato data cambiato in",
    sd_of: constVarLan["sd_of"],
    sd_moreInfo: `${constVarLan["sd_moreIn"]} (${constVarLan["sd_in_aNewWindow"]})`,
    sd_orLess: constVarLan["sd_orLess"],
    sd_interoperable: constVarLan["sd_interoperable"],
    sd_registeredTitlePrefix: constVarLan["sd_registeredTitlePrefix"],
    sd_PermittedExtensions: constVarLan["sd_PermittedExtensions"],
    ts_leadtrailRemoved: "rimuovi caratteri non-ascii e speciali iniziali e finali",
    ts_emailmusthaveat: "Un " + constVarLan["sd_Email_ad"] + " deve avere un \"\@\" !",
    ts_emailmusthavedomain: "Un " + constVarLan["sd_Email_ad"] + " deve terminare con \"domain.topLevelDomain\" ad esempio \"ti.ch\" !",
    ts_wrongEmailSeparators: constVarLan["sd_emailEntryTooltip"] + " - " + constVarLan["sd_not"] + " \";\" (" + constVarLan["sd_semicolon"] + ") !",
    ts_emailmustonlyone: "È consentito solo un " + constVarLan["sd_Email_ad"] + " !",
    ts_extraDotRemovedfromAdjacenttoAt: "il punto extra viene rimosso per adiacente a \"\@\" !",
    ts_invalidAHVNummerRemoved: "Il Numero AVS non valido è stato rimosso!",
    ts_nonMatchingCharsRemoved: "lettere invalidi retirati!",
    sd_Country: constVarLan["sd_Country"],
    sd_mobileNrForQesAuthoriz: constVarLan["sd_mobileNrForQesAuthoriz"],
    sd_GivenName: constVarLan["sd_GivenName"],
    sd_Lastname: constVarLan["sd_Lastname"],
    sd_SubmitTxt: constVarLan["sd_SubmitTxt"],
    ts_phoneNumber: "Numero di telefono",
    ts_enterQESInfo: "Inserisci le informazioni QES",
    ts_defaultError: "Qualcosa è andato storto, per favore riprova",
    ts_fillInAllFields: "Si prega di compilare tutti i campi",
    ts_phoneNumberError: "Assicurati che il numero di telefono sia corretto",
    ts_emailError: "Assicurati che l'email sia corretta",
    ts_qesSubmittSuccess: "Le informazioni QES sono state inviate con successo",
    ts_qesEmailNotFound: "La tua email non è stata trovata nel sistema, si prega di inserire il nome e il cognome",
    ts_qesError: "Impossibile aggiornare le informazioni QES",
    ts_because: "perché",
    ts_qesErrorSession: "Impossibile verificare la sessione QES",
    ts_success: "Successo",
    ts_countryCodeError: "Il codice del paese ricevuto è errato",
    ts_qesNameNotFound: "Il nome o il cognome non possono essere vuoti",
    ts_longWaitTimeTitle: "Tempi di attesa più lunghi del solito",
    ts_longWaitTimeText1: "Ci scusiamo per i lunghi tempi di attesa! Purtroppo l'elaborazione della tua richiesta sta richiedendo più tempo del previsto. È possibile che la tua richiesta sia già stata elaborata e inviata al destinatario corretto. In questo caso, controlla se hai ricevuto una ricevuta di consegna da PrivaSphere. Questa ricevuta di invio costituisce una conferma legale che il modulo è stato inviato.",
    ts_longWaitTimeText2: "Se hai ricevuto una ricevuta di deposito ma la pagina del modulo mostra ancora che è in stato di invio, ti preghiamo di segnalarci questo errore tramite: ",
    ts_includeLogs: "Si prega di scaricare i log e includerli nell'email come allegato.",
    ts_downloadLogs: "Scarica Log",
    ts_inputNumber: "Numero di input",
    ts_inputAmendedWithProperDots: "L'input è stato modificato con i punti corretti",
    ts_RemovedNonLetters: "I caratteri non alfabetici sono stati rimossi.",
    ts_error: "Errore",
    ts_whiteSpacesAtStartEndRemoved: "Gli spazi all'inizio e alla fine sono stati rimossi.",
    ts_InvalidPhoneNumber: "Il numero di telefono non segue il formato corretto, si prega di correggerlo.",
    ts_secondsElapsedWaitPanel: "Fino adesso: {0}",
    ts_numberIsTooLow: "L'input è inferiore al minimo impostato '{0}'.",
    ts_numberIsTooHigh: "L'input è superiore al massimo impostato '{0}'.",
    ts_showRegexForUser: "L'espressione regolare è '{0}'. Se riscontri problemi, contatta {1} allegando uno screenshot di questo errore, incluso il nome del modulo e i log del browser nell'email.",
    ts_formSupportDialogTitle: "Richiedi supporto",
    ts_formSupportDialogBody: "Se hai difficoltà con il modulo o pensi che ci sia un errore, invia un'email al supporto e spiega il tuo problema.",
    sd_supportButtonInForm: constVarLan["sd_supportButtonInForm"],
    ts_numberOutOfRange: "L'input {0} è fuori dall'intervallo consentito. ",
    ts_minAllowed: "Il minimo consentito è {0}",
    ts_maxAllowed: "Il massimo consentito è {0}",
    ts_fileAlreadyExists: "Questo input può contenere solo un file alla volta e ne contiene già uno. Se vuoi caricarne uno nuovo, elimina il vecchio file."
};
