/**
 * @author gudiskis PrivaSphere confidential, (c) 2023 - 2024 all rights reserved
 */
import { LoggerFactory } from 'myd-commons';
import { deTranslation } from './LangueFormat.de';
import { enTranslation } from './LangueFormat.en';
import { frTranslation } from './LangueFormat.fr';
import { itTranslation } from './LangueFormat.it';
const logger = LoggerFactory.getLogger('LangueFormat');
export const translations = {
    en: enTranslation,
    de: deTranslation,
    fr: frTranslation,
    it: itTranslation
};
export function getCurrentTranslation() {
    if (typeof currentLanguageTS !== 'undefined') {
        return getTranslation(currentLanguageTS);
    }
    logger.warn(`currentLanguageTS is not set in inline. Defaulting to en`);
    return translations['en'];
}
export function getTranslation(key) {
    if (key && isSupportedLanguage(key)) {
        return translations[key];
    }
    logger.warn(`Language ${key} is not supported. Defaulting to en`);
    return translations['en'];
}
function isSupportedLanguage(key) {
    return ['en', 'de', 'fr', 'it'].includes(key);
}
export function getTypo3LanguageNumber(currentLanguageTS) {
    switch (currentLanguageTS) {
        case 'en':
            return 0;
        case 'de':
            return 1;
        case 'fr':
            return 3;
        case 'it':
            return 4;
        default:
            return 0;
    }
}
export const translationCurrent = getCurrentTranslation();
